/* eslint-disable react-hooks/exhaustive-deps */
import { Viewer, Worker, LocalizationMap } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BounceLoader from 'react-spinners/BounceLoader';
import { IMaterialCleanDTO } from '../../models/happy-code-api.model';
import { useMaterialService } from '../../services/material.service';
import Anottations from './annotations';
import toolbar from './toolbar';
import pt_BR from '@react-pdf-viewer/locales/lib/pt_PT.json';
import './_style.scss';

function RenderPdf() {
  const materialService = useMaterialService();
  const { id } = useParams();
  const [file, setFile] = useState<IMaterialCleanDTO>();
  const { renderToolbar } = toolbar();
  const {
    sidebarTabs,
    renderHighlightContent,
    renderHighlights,
    renderHighlightTarget,
  } = Anottations();

  const findArquivo = useCallback(async () => {
    await materialService
      .findCleanById({ id: parseInt(id) })
      .then((response) => {
        setFile(response?.data);
      });
  }, [id]);

  useEffect(() => {
    findArquivo();
  }, []);

  const highlightPluginInstance = highlightPlugin({
    renderHighlightTarget,
    renderHighlightContent,
    renderHighlights,
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar,
    sidebarTabs,
  });

  useEffect(() => {
    const pdfContainer = document.querySelector('.pdf');
    if (!pdfContainer) return;

    const handleLinkClick = (e) => {
      const target = e.target.closest('a');
      if (target && target.href) {
        e.preventDefault();
        window.open(target.href, '_blank');
      }
    };

    pdfContainer.addEventListener('click', handleLinkClick);

    return () => {
      pdfContainer.removeEventListener('click', handleLinkClick);
    };
  }, [file?.enderecoArquivo]);

  useEffect(() => {
    const handleWheelCapture = (event) => {
      if (event.ctrlKey) {
        event.stopImmediatePropagation();
        event.preventDefault();
      }
    };

    window.addEventListener('wheel', handleWheelCapture, { capture: true });

    return () => {
      window.removeEventListener('wheel', handleWheelCapture, {
        capture: true,
      });
    };
  }, []);
  return (
    <div className="pdf-container">
      {file?.enderecoArquivo ? (
        <div className="pdf">
          <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
            <Viewer
              defaultScale={1.0}
              localization={pt_BR as LocalizationMap}
              plugins={[defaultLayoutPluginInstance, highlightPluginInstance]}
              fileUrl={file.enderecoArquivo}
            />
          </Worker>
        </div>
      ) : (
        <div>
          <BounceLoader color={'#0C306C'} />
        </div>
      )}
    </div>
  );
}

export default RenderPdf;
