import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import UserWelcomeCard from '../../../../components/user-welcome-card/user-welcome-card.component';
import '../../../../global.css';
import '../../style.css';
import SidebarAndContainer from '../../../../components/sidebar-and-container/sidebar-and-container.component';
import { useListTurmaDataStore } from '../../../../store/turmas/list-turma-data.store';
import useTurmas from './use-turmas.hook';
import { TurmaStatusForInput } from '../../../../enum/turmas/turma-status-for-input.enum';
import TabelaTurmasComponent from '../../components/tabela-turmas-component/tabela-turmas.component';

export const Turmas: React.FunctionComponent = () => {
  const { pageableTurmaData } = useListTurmaDataStore();

  const listTurmaTotalElements = pageableTurmaData?.totalElements;

  const {
    turmasKpi,
    listOfCursos,
    listOfModalidades,
    inputValuesObject,
    handleInputsFiltersObject,
    tableColumns,
    onPageChange,
    goToDetalhe,
  } = useTurmas();

  const { listTurmaData } = useListTurmaDataStore();

  return (
    <SidebarAndContainer gestaoSalaAula={true}>
      <UserWelcomeCard />
      <div className="box-title">
        <div id="turma">
          <h3>Turmas ({listTurmaTotalElements})</h3>
          <p className="mb-20">
            {listTurmaTotalElements} turma(s) em andamento -{' '}
            {turmasKpi?.quantidadeAlunosAtivo} aluno(s) ativo(s)
          </p>
        </div>
      </div>
      <div className="filtros">
        <div className="input-text">
          <Input
            id="buscar"
            onChange={(e) => handleInputsFiltersObject.searchbar(e)}
            placeholder="Buscar"
            prefix={<SearchOutlined style={{ color: '#6A7C96' }} />}
          />
        </div>
        <div className="filtro-turmas" id="input-dashboard">
          <span>Status</span>
          <Select
            defaultValue={TurmaStatusForInput.InProgress}
            onChange={handleInputsFiltersObject.status}
          >
            <Select.Option value={TurmaStatusForInput.InProgress}>
              Em andamento
            </Select.Option>
            <Select.Option value={TurmaStatusForInput.Finished}>
              Finalizado
            </Select.Option>
          </Select>
        </div>
        <div className="filtro-turmas" id="input-dashboard">
          <span>Modalidade</span>
          <Select
            defaultValue={'TDS'}
            value={inputValuesObject.modalidade}
            onChange={handleInputsFiltersObject.modalidade}
          >
            <Select.Option value="TDS">Todos</Select.Option>
            {listOfModalidades &&
              listOfModalidades.map(({ id, descricao }) => (
                <Select.Option key={id} value={id}>
                  {descricao}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div className="filtro-turmas" id="input-dashboard">
          <span>Curso</span>
          <Select
            defaultValue="TDS"
            onChange={handleInputsFiltersObject.curso}
            value={inputValuesObject.curso}
          >
            <Select.Option value="TDS">Todos</Select.Option>
            {listOfCursos &&
              listOfCursos.map(({ id, descricao }) => (
                <Select.Option key={id} value={id}>
                  {descricao}
                </Select.Option>
              ))}
          </Select>
        </div>
      </div>

      <TabelaTurmasComponent
        rows={listTurmaData}
        cols={tableColumns}
        onPageChange={onPageChange}
        goToDetalhe={goToDetalhe}
      />
    </SidebarAndContainer>
  );
};
