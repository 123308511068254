import Pagination from '@material-ui/lab/Pagination';
import { Select } from 'antd';
import SidebarAndContainer from '../../components/sidebar-and-container/sidebar-and-container.component';
import Title from '../../components/title/title.component';
import UserWelcomeCard from '../../components/user-welcome-card/user-welcome-card.component';
import { ProductType } from '../../enum/materiais/product-type.enum';
import {
  ICurriculoFullDTO,
  ICursoFullDTO,
  IProdutoEscolaFullDTO,
} from '../../models/happy-code-api.model';
import ProductTypeTableHeader from './components/product-type-table-header/product-type-table-header.component';
import { ProductTypeTableRow } from './components/product-type-table-row/product-type-table-row.component';
import './materiais.styles.scss';
import useMateriais from './use-materiais.hook';

export const Materiais = () => {
  const {
    cursos,
    curriculos,
    productStemplay,
    materiaisTitleBasedOnProductType,
    productType,
    handleProductTypeChange,
    initialProductType,
    tableHeaderText,
    selectTypeMaterial,
  } = useMateriais();

  return (
    <>
      <SidebarAndContainer materiais={true}>
        <UserWelcomeCard />
        <div className="materiais-container__top-materiais-informations">
          <div className="top-materiais-informations__title-label">
            <Title title={materiaisTitleBasedOnProductType()} />
            <p>Materiais de apoio para preparação das aulas</p>
          </div>

          <div className="top-materiais-information__select-tipo">
            <label className="w-100 nowrap">Tipo Material</label>
            <Select
              defaultValue={initialProductType}
              onChange={(e) => handleProductTypeChange(e)}
              style={{
                width: '150px',
                height: '40px',
                borderRadius: '8px',
                backgroundColor: 'var(--white-color)',
              }}
            >
              {selectTypeMaterial}
            </Select>
          </div>
        </div>

        <div className="materiais-container__table">
          <ProductTypeTableHeader tableHeaderText={tableHeaderText} />
          <div className="w-100">
            {productType === ProductType.Curso &&
              cursos.data?.content.map((curso: ICursoFullDTO) => (
                <ProductTypeTableRow
                  key={curso.id}
                  image="assets/img/item/digital-arts.svg"
                  url={`/materiais/${curso?.id}/aula`}
                  item={curso}
                />
              ))}
            {productType === ProductType.Curriculo &&
              curriculos.data?.content?.map((curriculo: ICurriculoFullDTO) => (
                <ProductTypeTableRow
                  key={curriculo.id}
                  image="assets/img/item/digital-arts.svg"
                  url={`/materiais/curriculo/${curriculo?.id}/aula`}
                  item={curriculo}
                />
              ))}
            {productType === ProductType.ProdutoEscola &&
              productStemplay &&
              productStemplay?.data?.content.map(
                (produto: IProdutoEscolaFullDTO) => (
                  <ProductTypeTableRow
                    key={produto.id}
                    image="assets/img/item/digital-arts.svg"
                    url={`/materiais/produto-escola/${produto.id}/aula`}
                    item={produto}
                  />
                ),
              )}
          </div>
        </div>
        <div className="d-flex-row j-center-a-center w-100 pb-50">
          {productType === ProductType.Curso && (
            <Pagination
              onChange={(event, page) => cursos.onPageChange(event, page - 1)}
              page={cursos.data ? cursos.data?.pageable.pageNumber + 1 : 0}
              count={cursos.data ? cursos.data?.totalPages : 0}
            />
          )}
          {productType === ProductType.Curriculo && (
            <Pagination
              onChange={(event, page) =>
                curriculos.onPageChange(event, page - 1)
              }
              page={
                curriculos.data ? curriculos.data?.pageable.pageNumber + 1 : 0
              }
              count={curriculos.data ? curriculos.data?.totalPages : 0}
            />
          )}
          {productType === ProductType.ProdutoEscola && (
            <Pagination
              onChange={(event, page) =>
                productStemplay.onPageChange(event, page - 1)
              }
              page={
                productStemplay?.data
                  ? productStemplay?.data?.pageable.pageNumber + 1
                  : 0
              }
              count={
                productStemplay?.data ? productStemplay?.data?.totalPages : 0
              }
            />
          )}
        </div>
      </SidebarAndContainer>
    </>
  );
};
