import { useCallback, useState } from 'react';
import { PageableResponse } from '../../../models/response.model';
import {
  IProdutoEscolaDTO,
  IProdutoEscolaFullDTO,
} from '../../../models/happy-code-api.model';
import { useProdutoEscolaService } from '../../../services/produto-escola.service';
import useAddPredicateOnQueryParam from './use-add-predicate-on-query-param.hook';
import { useGlobalContext } from '../../../context/GlobalContext';
import { useHandlePageChangeHook } from '../../../hooks/use-handle-page-change.hook';

export default function useFindProductStemplay() {
  const produtoService = useProdutoEscolaService();
  const onPageChange = useHandlePageChangeHook();
  const { addPredicateOnQueryParam } = useAddPredicateOnQueryParam();
  const { usuario } = useGlobalContext();
  const { acessoB2b } = usuario || {};

  const [productStemplay, setProductStemplay] =
    useState<PageableResponse<IProdutoEscolaFullDTO>>();

  const findProductStemplay = useCallback(
    async (page?: number): Promise<void> => {
      if (!acessoB2b) return;

      const predicate = addPredicateOnQueryParam(page);

      await produtoService
        .stemplayProduto(predicate)
        .then(({ data }) => setProductStemplay(data));
    },
    [acessoB2b, addPredicateOnQueryParam],
  );

  return {
    productStemplay: {
      data: productStemplay,
      getData: findProductStemplay,
      onPageChange: onPageChange(findProductStemplay),
    },
  };
}
