import React, { ChangeEvent, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import CabecalhoTabelaTurmasComponent from '../cabecalho-tabela-turmas-component/cabecalho-tabela-turmas.component';
import '../../style.css';
import { ITurmaDTO } from '../../../../models/happy-code-api.model';
import { Tags } from '../../../../components/Tags';
import * as Unicons from '@iconscout/react-unicons';
import { useListTurmaDataStore } from '../../../../store/turmas/list-turma-data.store';
import { ITurmaDataTable } from '../../../../interfaces/turmas/turma-data-table.interface';

interface TabelaTurmasComponentProps {
  rows: ITurmaDataTable[];
  cols: {
    field: string;
    headerName: string;
    width: number;
  }[];
  onPageChange: (event: ChangeEvent<any>, page: number) => void;
  goToDetalhe: (turma: ITurmaDTO) => void;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: 10,
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function getComparator<Key extends keyof any>(
  order: any,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(
  array: ITurmaDataTable[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const TabelaTurmasComponent: React.FC<TabelaTurmasComponentProps> = ({
  rows,
  cols,
  onPageChange,
  goToDetalhe,
}) => {
  const [order, setOrder] = useState<any>('desc');
  const [orderBy, setOrderBy] = useState<any>('nmTurma');
  const [selected, setSelected] = useState<string[]>([]);
  const page = 0;
  const rowsPerPage = 10;

  const classes = useStyles();

  const { pageableTurmaData } = useListTurmaDataStore();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const statusTurma = ({ status }: ITurmaDataTable) => {
    if (status === 'Não iniciado') {
      return (
        <div className="estado-user">
          <div className="dot-orange"></div>
          <p className="inativo" style={{ marginRight: 10 }}>
            Não Iniciado
          </p>
        </div>
      );
    }
    if (status === 'Em andamento') {
      return (
        <div className="estado-user">
          <div className="dot-verde"></div>
          <p className="ativo" style={{ marginRight: 10 }}>
            Em andamento
          </p>
        </div>
      );
    }
    if (status === 'Finalizado') {
      return (
        <div className="estado-user">
          <div className="dot-orange"></div>
          <p className="inativo" style={{ marginRight: 10 }}>
            Finalizado
          </p>
        </div>
      );
    }
  };

  return (
    <div className="tabela">
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <CabecalhoTabelaTurmasComponent
            classes={classes}
            headCells={cols}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />

          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    // selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <p className="funcao-user">{row.codigo}</p>
                      <div className="estado-user">
                        <h4 className="ativo">{statusTurma(row as any)}</h4>
                      </div>
                    </TableCell>
                    <TableCell
                      className="funcao-user"
                      align="left"
                      width={'15%'}
                    >
                      <Tags tipo={row.curso as string} />
                    </TableCell>
                    <TableCell
                      className="funcao-user"
                      align="left"
                      width={'15%'}
                    >
                      <Tags tipo={row.cursoModalidadeDescricao as string} />
                    </TableCell>
                    <TableCell className="funcao-user" align="left">
                      <p>{row.pessoaNome}</p>
                    </TableCell>
                    <TableCell className="funcao-user" align="left">
                      <p>{row.dtInicio}</p>
                    </TableCell>
                    <TableCell className="funcao-user" align="left">
                      <p>{row.dtTermino}</p>
                    </TableCell>
                    <TableCell
                      className="funcao-user"
                      align="left"
                      onClick={() => {
                        goToDetalhe(row as any);
                      }}
                    >
                      <div style={{ width: '5%', cursor: 'pointer' }}>
                        <Unicons.UilEye size="20" color="#92A9CB" />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="paginacao">
        <Pagination
          onChange={(event, page) => onPageChange(event, page - 1)}
          page={
            pageableTurmaData ? pageableTurmaData.pageable.pageNumber + 1 : 0
          }
          count={pageableTurmaData ? pageableTurmaData.totalPages : 0}
        />
      </div>
    </div>
  );
};

export default TabelaTurmasComponent;
