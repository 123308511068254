import {
  BrowserRouter,
  Route,
  Routes as RoutesWrapper,
} from 'react-router-dom';
import PrivateRoute from './config/private-route';
import { TurmaContextProvider } from './context/TurmaContext';
import { Aula } from './pages/Aula';
import ConectaApresentacao from './pages/ConectaApresentacao';
import { ConectasAll } from './pages/Conectas/conectas-all.page';
import { CursosAll } from './pages/Cursos/all-cursos/all-cursos.page';
import { HomeCursos } from './pages/Cursos/cursos.page';
import { CursosSearch } from './pages/Cursos/search';
import { CursosTrilha } from './pages/Cursos/trilha-id/trilha-id.page';
import { Favoritos } from './pages/Dashboard/favoritos';
import { MeusCursos } from './pages/Dashboard/meus-cursos';
import { Instrutor } from './pages/Instrutor/instrutor.page';
import { InstrutorTurmas } from './pages/InstrutorTurmas';
import { Email } from './pages/Login/email';
import { Login } from './pages/Login/index';
import { Senha } from './pages/Login/senha';
import { Materiais } from './pages/Materiais/materiais.page';
import { Arquivos } from './pages/Materiais/arquivos';
import { ArquivosCurriculo } from './pages/Materiais/arquivos-curriculo';
import { ArquivosProdutoEscola } from './pages/Materiais/arquivos-produto-escola';
import { MateriaisAula } from './pages/Materiais/aula';
import { MateriaisAulaCurriculo } from './pages/Materiais/aula-curriculo';
import { MateriaisAulaProdutoEscola } from './pages/Materiais/aula-produto-escola';
import { Mentorias } from './pages/Mentorias';
import { MentoriasDetalhe } from './pages/Mentorias/detalhe';
import { MentoriasHistorico } from './pages/Mentorias/historico';
import { MentoriasMentores } from './pages/Mentorias/mentores';
import { MentoriasPerfil } from './pages/Mentorias/perfil';
import { RecuperarSenha } from './pages/RecuperarSenha';
import { Turmas } from './pages/Turmas/pages/index/index.page';
import { TurmasDetalhe } from './pages/Turmas/detalhe';
import { LancamentoAula } from './pages/Turmas/lancamento-aula';
import { TurmasObservacoes } from './pages/Turmas/observacoes';
import { TurmasSkills } from './pages/Turmas/pages/skills/skills';
import { TurmasCreateStep1 } from './pages/Turmas/step-1';
import { TurmasCreateStep2 } from './pages/Turmas/step-2';
import { TurmasCreateStep3 } from './pages/Turmas/step-3';
import RenderPdf from './pages/Unity';
import Profile from './pages/Usuarios/profile';
import UserSenha from './pages/Usuarios/senha';
import WebinarApresentacao from './pages/WebinarApresentacao';
import { WebinarClassificacao } from './pages/WebinarApresentacao/classificacao';
import { WebinarsAll } from './pages/Webinars/webinars-all.page';
import { CursosAula } from './pages/Cursos/cursos-aulas/cursos-aulas.page';

export const Routes = () => {
  const Principal = Instrutor;
  return (
    <BrowserRouter>
      <TurmaContextProvider>
        <RoutesWrapper>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/senha" element={<Senha />}></Route>
          <Route path="/email" element={<Email />}></Route>
          <Route
            path="/recuperar-senha/:token"
            element={<RecuperarSenha />}
          ></Route>

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Principal />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/usuarios/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/default"
            element={
              <PrivateRoute>
                <Principal />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/instrutor"
            element={
              <PrivateRoute>
                <Instrutor />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/meus-cursos"
            element={
              <PrivateRoute>
                <MeusCursos />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/favoritos"
            element={
              <PrivateRoute>
                <Favoritos />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/cursos"
            element={
              <PrivateRoute>
                <HomeCursos />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/cursos/all"
            element={
              <PrivateRoute>
                <CursosAll />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/cursos/search"
            element={
              <PrivateRoute>
                <CursosSearch />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/cursos/trilha/:idTrilha"
            element={
              <PrivateRoute>
                <CursosTrilha />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/cursos/:idCurso/trilha/:idTrilha"
            element={
              <PrivateRoute>
                <CursosAula />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/materiais"
            element={
              <PrivateRoute>
                <Materiais />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/materiais/:idCurso/aula"
            element={
              <PrivateRoute>
                <MateriaisAula />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/materiais/curriculo/:idCurriculo/aula"
            element={
              <PrivateRoute>
                <MateriaisAulaCurriculo />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/materiais/produto-escola/:idProdutoEscola/aula"
            element={
              <PrivateRoute>
                <MateriaisAulaProdutoEscola />
              </PrivateRoute>
            }
          ></Route>

          {/*
                  <Route
                      path="/materiais/:idCurriculo"
                      element={
                          <PrivateRoute>
                              <MateriaisAula />
                          </PrivateRoute>
                      }
                  ></Route> */}

          <Route
            path="/materiais/aula/:idAula/arquivos"
            element={
              <PrivateRoute>
                <Arquivos />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/materiais/aula/curriculo/:idAula/arquivos/:idCurriculo"
            element={
              <PrivateRoute>
                <ArquivosCurriculo />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/materiais/aula/produto-escola/:idAula/arquivos"
            element={
              <PrivateRoute>
                <ArquivosProdutoEscola />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/aula/:id"
            element={
              <PrivateRoute>
                <Aula />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/mentorias"
            element={
              <PrivateRoute>
                <Mentorias />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/mentorias/mentores"
            element={
              <PrivateRoute>
                <MentoriasMentores />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/mentorias/mentores/perfil"
            element={
              <PrivateRoute>
                <MentoriasPerfil />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/mentorias/historico"
            element={
              <PrivateRoute>
                <MentoriasHistorico />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/mentorias/historico/detalhe"
            element={
              <PrivateRoute>
                <MentoriasDetalhe />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/usuarios/senha"
            element={
              <PrivateRoute>
                <UserSenha />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/webinars/all"
            element={
              <PrivateRoute>
                <WebinarsAll />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/webinar-apresentacao/:idWebinar"
            element={
              <PrivateRoute>
                <WebinarApresentacao />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/webinars/feedback/:idWebinar"
            element={
              <PrivateRoute>
                <WebinarClassificacao />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/conectas/all"
            element={
              <PrivateRoute>
                <ConectasAll />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/conecta-apresentacao/:idConecta"
            element={
              <PrivateRoute>
                <ConectaApresentacao />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/render-pdf/:id"
            element={
              <PrivateRoute>
                <RenderPdf />
              </PrivateRoute>
            }
          ></Route>
        </RoutesWrapper>

        <RoutesWrapper>
          <Route
            path="/instrutor/turmas"
            element={
              <PrivateRoute>
                <InstrutorTurmas />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas"
            element={
              <PrivateRoute>
                <Turmas />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/professor"
            element={
              <PrivateRoute>
                <Turmas />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/detalhe/:id"
            element={
              <PrivateRoute>
                <TurmasDetalhe />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/professor/detalhe/:id"
            element={
              <PrivateRoute>
                <TurmasDetalhe />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/professor/lancamento-aula/:id"
            element={
              <PrivateRoute>
                <LancamentoAula />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/skills/aluno/:matriculaId"
            element={
              <PrivateRoute>
                <TurmasSkills />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/observacoes/aluno/:alunoId/frequencia/:idFrequencia"
            element={
              <PrivateRoute>
                <TurmasObservacoes />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/create/step-1"
            element={
              <PrivateRoute>
                <TurmasCreateStep1 />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/create/step-2"
            element={
              <PrivateRoute>
                <TurmasCreateStep2 />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/turmas/create/step-3"
            element={
              <PrivateRoute>
                <TurmasCreateStep3 />
              </PrivateRoute>
            }
          ></Route>

          {/* <PrivateRoute path="/curso-franquia" exact element={CursoFranquia}></PrivateRoute> */}
          {/* <PrivateRoute path="/curso-franquia/turma" exact element={CursoFranquiaTurma}></PrivateRoute> */}
        </RoutesWrapper>
      </TurmaContextProvider>
    </BrowserRouter>
  );
};
