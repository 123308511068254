import * as Unicons from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';
import {
  ICurriculoFullDTO,
  ICursoFullDTO,
  IProdutoEscolaFullDTO,
} from '../../../../models/happy-code-api.model';
import './product-type-table-row.styles.scss';
import useProductTypeTable from './use-product-type-table.hook';

interface ProductTypeTableRowProps {
  image: string;
  url: string;
  item: ICursoFullDTO | ICurriculoFullDTO | IProdutoEscolaFullDTO;
}

export const ProductTypeTableRow = ({
  image,
  url,
  item,
}: ProductTypeTableRowProps) => {
  const { findOutWhatDataToUse } = useProductTypeTable();

  const { descricao, negocio, duracao, quantidadeMaterial } =
    findOutWhatDataToUse(item);

  return (
    <div className="product-type-table-row">
      <div className="product-type-table-row__thumb-course-description">
        <img src={image} alt={`${descricao} thumbnail`} />
        <p className="thumb-course-description__description">{descricao}</p>
      </div>
      <div className="product-type-table-row__item">{negocio}</div>
      <div className="product-type-table-row__item">{duracao}</div>
      <div className="product-type-table-row__item">{quantidadeMaterial}</div>

      <div className="product-type-table-row__item">
        <Link
          to={url}
          className="button-primary product-type-table-row__button"
        >
          Ver Aulas <Unicons.UilArrowRight />
        </Link>
      </div>
    </div>
  );
};
